const $ = require('jquery');
global.$ = global.jQuery = $;

import 'bootstrap';
import AOS from 'aos';


//AOS.init();
AOS.init({disable: 'mobile'});


console.log("hola");
